import React from 'react';
import { Global } from '@theme';
import { Layout } from '@components';
import { SEO } from '@components';
import {
  ThankYouSection
} from '@screens/Main';

const TitlePage3 = () => (
  <>
    <Global />
    <Layout>
      <SEO title="Entraremos em contato" />
      <ThankYouSection/>
    
    </Layout>
  </>
);

export default TitlePage3;
